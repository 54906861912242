<template>
  <b-row class="match-height">
    <b-col
      class="d-flex justify-content-center"
    >
      <div>
        © {{ new Date().getFullYear() }}
        <b-link
          href="https://eurocam.com.tr/"
          target="_blank"
        >Eurocam & Plastik </b-link>
        <span class="d-none d-sm-inline-block"> Tüm hakları saklıdır.</span>
      </div>
    </b-col>
    <b-col
      cols="auto"
      class="d-flex justify-content-center"
    >
      <b-button
        v-b-modal.modal-privacy
        variant="flat-primary"
        @click="getContent(1)"
      >Gizlilik Güvenlik
      </b-button>
    </b-col>
    <b-col
      cols="auto"
      class="d-flex justify-content-center"
    >
      <b-button
        v-b-modal.modal-members
        variant="flat-primary"
        @click="getContent(2)"
      >Üyelik Sözleşmesi
      </b-button>
    </b-col>
    <b-col
      cols="auto"
    >
      <b-img
        src="/images/logos.png"
        width="250"
        class="ml-auto"
      />
    </b-col>
    <privacy-modal />
    <members-modal />
  </b-row>
</template>

<script>
import {
  BLink, BImg, BRow, BCol, BButton,
} from 'bootstrap-vue'
import PrivacyModal from '@/layouts/components/PrivacyModal.vue'
import MembersModal from '@/layouts/components/MembersModal.vue'

export default {
  components: {
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    PrivacyModal,
    MembersModal,
  },
  methods: {
    getContent(id) {
      this.$store.dispatch('contents/getDataItem', id)
    },
  },
}
</script>
