<template>
  <b-modal
    id="modal-members"
    :title="modalData.title"
    size="lg"
    hide-footer
    scrollable
  >
    <div v-html="modalData.content" />
  </b-modal>
</template>
<script>
import { BModal } from 'bootstrap-vue'

export default {
  name: 'MembersModal',
  components: {
    BModal,
  },
  computed: {
    modalData() {
      return this.$store.getters['contents/dataItem']
    },
  },
}
</script>
